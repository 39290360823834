<div class="p-1">
    <div class="border rounded">
        <div class="pt-3 d-flex align-items-center justify-content-center">

            <h2 [ngClass]="locale" i18n="logOutModal: Confirm Logout">Confirm Logout</h2>
        </div>
        <div class="pt-3 d-flex flex-column ">
            <div class="mb-3 p-3 d-flex justify-content-center _title"  i18n="logOutModal: Are you sure you want to logout?">
                Are you sure you want to logout?
            </div>
            <div class="mt-3 pb-3 d-flex align-items-center justify-content-around">
                <button i18n="logOutModal: Yes Logout" class="btn btn-login rounded-pill" type="button" (click)="confirmLogout()">Yes Logout </button>
                <button i18n="logOutModal: Cancel" class="btn btn-out rounded-pill" type="button" (click)="cancel()">Cancel</button>
            </div>
        </div>
    </div>
</div>
