<!-- Mobile Navbar -->
<div class="mob navbar-mob" style="z-index: 900;" [@fadeTopHeader]="isTopHeaderVisible ? 'visible' : 'hidden'">
    <div class="container-fluid">
        <div class="d-flex align-items-center justify-content-between gap-2">

            <img *ngIf="getCurrentLang()=='en'" style="cursor: pointer;" src="./assets/images/DP education logo 2x.png"
                alt="" (click)="gotohome()">
            <img *ngIf="getCurrentLang()=='si'" style="cursor: pointer;"
                src="./assets/images/DP education logo 2x si.png" alt="" (click)="gotohome()">
            <img *ngIf="getCurrentLang()=='ta'" style="cursor: pointer;"
                src="./assets/images/DP education logo 2x ta.png" alt="" (click)="gotohome()">

            <div class="seasonal-banner w-100">
            </div>

            <div class="dropdown">
                <button class="btn btn-lang text-dark dropdown-toggle bg-white" type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <ng-container *ngIf="getCurrentLang()=='si'">සිං</ng-container>
                    <ng-container *ngIf="getCurrentLang()=='ta'">தமிழ்</ng-container>
                    <ng-container *ngIf="getCurrentLang()=='en'">Eng</ng-container>
                </button>
                <ul class="dropdown-menu">
                    <li><a type="button" href="/" class="btn" [ngClass]="{ 'active': getCurrentLang()=='si' }">සිං</a>
                    </li>
                    <li><a type="button" href="/ta/" class="btn"
                            [ngClass]="{ 'active': getCurrentLang()=='ta' }">தமிழ்</a></li>
                    <li><a type="button" href="/en/" class="btn"
                            [ngClass]="{ 'active': getCurrentLang()=='en' }">Eng</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>

<!-- Mobile Secondary Navbar -->
<div class="mob secondary-navbar-mob" style="z-index: 1000;"  [ngClass]="{ 'sticky': !isTopHeaderVisible }">
    <div class="container-fluid">
        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <button *ngIf="!showGlobalSearchMobile" type="button" class="btn btn-outline-secondary btn-prime d-flex"
                    data-bs-toggle="offcanvas" data-bs-target="#mobMenu" aria-controls="mobMenu">
                    <i class="bi bi-list"></i>
                </button>
                <button *ngIf="showGlobalSearchMobile" (click)="hideGlobalSearchMobile()" type="button"
                    class="btn btn-outline-secondary btn-prime d-flex">
                    <i class="bi bi-x-lg"></i>
                </button>
            </div>
            <div class="d-flex align-items-center mobile-global-search-wrapper"
                [ngClass]="{'active': showGlobalSearchMobile}">
                <global-search class="global-search" (click)="displayGlobalSearchMobile()"></global-search>
            </div>

        </div>
    </div>
</div>

<!-- Off canvas of Mobile -->
<div class="offcanvas offcanvas-start" tabindex="-1" id="mobMenu" aria-labelledby="mobMenuContainer">
    <div class="offcanvas-header">
        <h5 class="offcanvas-title fw-bold" id="mobMenuContainer" i18n="dp education: dp education">DP Education</h5>
        <button type="button" class="btn-close text-reset text-white" data-bs-dismiss="offcanvas"
            aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
        <p class="navbar-title" i18n="largest learning platform: Largest learning platform In Sri Lanka">Largest
            learning platform In Sri Lanka</p>
        <div class="d-flex flex-column">
            <a i18n="offcanvas: about us" href="./about-us" class="nav-item border-0">About Us</a>
            <a i18n="offcanvas: impact" href="./impact" class="nav-item border-0">Impact</a>
            <!-- <a  href="#grades-sec" class="nav-item">Grades</a> -->
            <div class="nav-item dropdown border-0">
                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false" i18n="offcanvas: grades">Grades
                </a>
                <ul class="dropdown-menu p-3">
                    <ng-container *ngFor="let grade of grades">
                        <li class="mb-2">
                            <a *ngIf="12 > grade['id']" class="text-dark" href="./grade/{{grade['id']}}">{{
                                grade[locale]}}</a>
                        </li>
                        <li class="mb-2">
                            <a *ngIf="grade['id'] >= 12" class="text-dark" href="./grade/{{grade['id']}}/stream">{{
                                grade[locale]}}</a>
                        </li>
                    </ng-container>
                </ul>
            </div>
            <a i18n="offcanvas: resources" href="./resources" class="nav-item border-0">Resources</a>
            <a i18n="offcanvas: what we offer" href="./what-we-offer" class="nav-item border-0">What We Offer</a>
            <a i18n="offcanvas: news room" href="https://blog.dpeducation.lk/" target="_blank"
                class="nav-item border-0">Blog</a>
            <a i18n="offcanvas: contact" href="./contact" class="nav-item border-0">Contact</a>

            <a i18n="offcanvas: login" href="" *ngIf="!isLoggedIn" class="nav-item me-0 border-0"
                [routerLink]="['/login']">Login</a>
            <a i18n="offcanvas: logout" class="nav-item me-0 border-0" *ngIf="isLoggedIn" (click)="logout()">Logout</a>
        </div>
    </div>
</div>

<!-- Web Top Header -->
<div class="web top-header container-fluid" [@fadeTopHeader]="isTopHeaderVisible ? 'visible' : 'hidden'">
    <div class="d-flex align-items-center justify-content-between gap-3 w-100">
        <img *ngIf="getCurrentLang()=='en'" src="./assets/images/DP education logo 2x.png" width="100px" alt=""
            (click)="gotohome()">
        <img *ngIf="getCurrentLang()=='si'" src="./assets/images/DP education logo 2x si.png" width="100px" alt=""
            (click)="gotohome()">
        <img *ngIf="getCurrentLang()=='ta'" src="./assets/images/DP education logo 2x ta.png" width="100px" alt=""
            (click)="gotohome()">

        <div class="seasonal-banner w-100">
        </div>

        <div class="d-flex align-items-center">
            <a type="button" [ngClass]="{ 'active': getCurrentLang()=='si' }" href="/" class="btn btn-lang">සිංහල</a>
            <a type="button" [ngClass]="{ 'active': getCurrentLang()=='ta' }" href="/ta/" class="btn btn-lang">தமிழ்</a>
            <a type="button" [ngClass]="{ 'active': getCurrentLang()=='en' }" href="/en/"
                class="btn btn-lang">English</a>
        </div>
    </div>
</div>

<!-- Web Navbar -->
<nav class="web navbar" [ngClass]="{ 'sticky': !isTopHeaderVisible }">
    <div class="container-fluid w-100 h-100 d-flex" [ngClass]="{'items-hidden': showGlobalSearch}"
        style="max-height:85px; flex-wrap: nowrap;">
        <div class="w-100 d-flex align-items-center gap-3 h-100 justify-content-center"
            [ngClass]="{'justify-content-end': showGlobalSearch, 'justify-content-center': !showGlobalSearch}">
            <div style="width: inherit;padding-top: 30px;" class="h-100 d-flex align-items-center web-nav-left">

                <div class="nav-item d-inline-block">
                    <a class="nav-link" [ngClass]="{'fw-semibold': locale === 'en'}" href=""
                        [routerLink]="['/about-us']" routerLinkActive="active" i18n="webNav: about us">About
                        Us</a>
                </div>

                <div class="nav-item d-inline-block">
                    <a class="nav-link" [ngClass]="{'fw-semibold': locale === 'en'}" [routerLink]="['/impact']"
                        routerLinkActive="active" i18n="webNav: impact">
                        Impact</a>
                </div>

                <div class="nav-item dropdown d-inline-block">
                    <div class="nav-link dropdown-toggle" [ngClass]="{'fw-semibold': locale === 'en'}" role="button"
                        data-bs-toggle="dropdown" aria-expanded="false" i18n="webNav: grades ">
                        Grades
                    </div>
                    <ul class="dropdown-menu p-3">
                        <ng-container *ngFor="let grade of grades">
                            <li class="mb-2">
                                <a *ngIf="12 > grade['id']" class="text-dark" href="./grade/{{grade['id']}}">{{
                                    grade[locale]}}</a>
                            </li>
                            <li class="mb-2">
                                <a *ngIf="grade['id'] >= 12" class="text-dark" href="./grade/{{grade['id']}}/stream">{{
                                    grade[locale]}}</a>
                            </li>
                        </ng-container>
                    </ul>
                </div>

                <div class="nav-item d-inline-block">
                    <a class="nav-link" [ngClass]="{'fw-semibold': locale === 'en'}" [routerLink]="['/resources']"
                        routerLinkActive="active" i18n="webNav: resources">
                        Resources</a>
                </div>

                <div class="nav-item d-inline-block">
                    <a class="nav-link" [ngClass]="{'fw-semibold': locale === 'en'}" [routerLink]="['/what-we-offer']"
                        routerLinkActive="active" i18n="webNav: what we offer">What We
                        Offer</a>
                </div>

                <div class="nav-item d-inline-block">
                    <a class="nav-link" [ngClass]="{'fw-semibold': locale === 'en'}" href="https://blog.dpeducation.lk/"
                        target="_blank" i18n="webNav: Newsroom">
                        Blog
                    </a>
                </div>

                <div class="nav-item d-inline-block">
                    <a class="nav-link" [ngClass]="{'fw-semibold': locale === 'en'}" [routerLink]="['/contact']"
                        routerLinkActive="active" i18n="webNav: contact">
                        Contact</a>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-end gap-3 search-block h-100"
                [ngClass]="{'active': showGlobalSearch}">
                <i [ngClass]="{'active': showGlobalSearch}" class="bi bi-x-lg close-search"
                    (click)="hideGlobalSearch();"></i>

                <global-search class="global-search" [ngClass]="{'active': showGlobalSearch}"
                    (click)="displayGlobalSearch()"></global-search>

                <div *ngIf="!isLoggedIn" class="nav-item me-0 h-100 d-flex align-items-center" i18n="webNav: login"
                    [routerLink]="['/login']">Login <i class="bi bi-person"></i></div>
                <div i18n="webNav: logout" class="nav-item me-0 h-100 d-flex align-items-center" *ngIf="isLoggedIn"
                    (click)="logout()">Logout</div>
            </div>
        </div>
    </div>
</nav>
<app-breadcrumb></app-breadcrumb>
<router-outlet></router-outlet>
<section id="footer">
    <div class="container">
        <div class="row">
            <div class="col-md-4 p-0">
                <div class="brand">
                    <div class="desc">
                        <div i18n="footer: mission">
                            We provide equal access to world-class digital learning, and healthcare facilities to
                            develop human capability to eliminate poverty
                        </div>
                        <br><br>
                        <div>
                            <span i18n="footer: DP Education is a 501(c)(3) nonprofit organization">DP Education is a Sri Lankan private foundation engaging in charitable activities. </span>
                            <a class="text-white text-decoration-underline" i18n="footer: volunteer" [routerLink]="['/contact']" routerLinkActive="router-link-active"  target="_blank">Volunteer</a> &nbsp;
                            <span i18n="footer: today"> today!</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8 p-2">
                <div class="row">
                    <div class="col-md-4 col-12">
                        <div class="link-group">
                            <a i18n="footer: home" href="./">Home</a>
                            <a i18n="footer: about us" href="./about-us">About Us</a>
                            <a i18n="footer: impact" href="./impact">Impact</a>
                            <a i18n="footer: what we offer" href="./what-we-offer">What We Offer</a>
                            <a i18n="footer: leadership" href="./leadership">Leadership</a>
                            <a i18n="footer: board of trustees" href="./board-of-directors">Board of Trustees</a>
                            <a i18n="footer: our team" href="./our-team">Our Team</a>
                            <a i18n="footer: content specialists" href="./content-specialists">Content Specialists</a>
                            <a i18n="footer: our partners" href="./our-partners">Our Partners</a>
                            <a i18n="footer: newsroom" href="https://blog.dpeducation.lk/" target="_blank">Blog</a>
                            <a i18n="footer: gallery" href="./gallery">Gallery</a>
                            <a i18n="footer: careers" href="./careers">Careers</a>
                        </div>
                    </div>
                    <div class="col-md-4 col-12">
                        <div class="link-group">
                            <p class="title" i18n="footer: connect with us">Connect with us</p>
                            <div class="link-group">
                                <a i18n="footer: help center" href="./help-center">Help Center</a>
                                <a i18n="footer: share your story" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdmD4ZRr0BLnoeIZUGY4aoSG97XqKTyqkQdhystEM9BthlOGw/viewform?usp=sf_link">Share your story</a>
                                <a i18n="footer: hotline">Hotline <br> 076 133 3666</a>
                                <a i18n="footer: email" href="mailto:info@dpedu.org">Email -
                                    info@dpedu.org</a>
                            </div>
                            <p class="title" i18n="footer: follow us on">Follow Us On</p>
                            <a class="social-icon d-inline me-3 mb-3" target="_blank"
                                href="https://www.facebook.com/dpeducation.org">
                                <img src="./assets/icons/social/footer/fb.png" alt="">
                            </a>
                            <a class="social-icon d-inline me-3 mb-3" target="_blank"
                                href="https://www.youtube.com/@DPEducationMathsSinhala"
                                i18n="youtube channel: Youtube channel">
                                <img src="./assets/icons/social/footer/yt.png" alt="">
                            </a>
                            <a class="social-icon d-inline me-3 mb-3" target="_blank"
                                href="https://www.instagram.com/dpeducation.lk/" i18n="insta page: Instagram Page">
                                <img src="./assets/icons/social/footer/ig.png" alt="">
                            </a>
                            <a class="social-icon d-inline me-3 mb-3" target="_blank"
                                href="https://whatsapp.com/channel/0029Va7crKwIt5rq8T0f6z1M">
                                <img src="./assets/icons/social/footer/wa.png" alt="">
                            </a>
                            <a class="social-icon d-inline me-3 mb-3" target="_blank" href="https://t.me/dpedugroup"
                                i18n="telegram channel: Telegram Channel">
                                <img src="./assets/icons/social/footer/tg.png" alt="">
                            </a>
                            <a class="social-icon d-inline me-3 mb-3" target="_blank"
                                href="https://www.tiktok.com/@dpeducation?is_from_webapp=1&sender_device=pc">
                                <img src="./assets/icons/social/footer/tt.png" alt="">
                            </a>
                            <a class="social-icon d-inline me-3 mb-3" target="_blank"
                                href="https://www.linkedin.com/company/dpfoundation/?viewAsMember=true">
                                <img src="./assets/icons/social/footer/in.png" alt="">
                            </a>
                            <a class="social-icon d-inline me-3 mb-3" target="_blank"
                                href="https://github.com/dpeducation" i18n="github: github link">
                                <img src="./assets/icons/social/footer/git.png" alt="">
                            </a>
                            <!-- <a class="social-icon d-inline me-3 mb-3" target="_blank"
                                href="https://discord.com/invite/yJQDHtCz9U" i18n="discord: discord">
                                <img src="./assets/icons/social/footer/discord.png" alt="">
                            </a> -->
                        </div>
                    </div>
                    <div class="col-md-4 col-12">
                        <div class="link-group">
                            <p class="title" i18n="footer: download our app " download our app>Download Our App</p>
                            <a class="download-icon mb-3" target="_blank"
                                href="https://play.google.com/store/apps/details?id=lk.databoxtech.dpeducation">
                                <img width="150px" src="./assets/icons/apps/gp.png" alt="">
                            </a>
                            <a class="download-icon mb-3" target="_blank"
                                href="https://apps.apple.com/us/app/dp-education/id1574530050"
                                i18n="App Store: App Store">
                                <img width="150px" src="./assets/icons/apps/as.png" alt="">
                            </a>
                            <a class="download-icon mb-3" target="_blank"
                                href="https://appgallery.huawei.com/#/app/C104504493">
                                <img width="150px" src="./assets/icons/apps/ag.png" alt="">
                            </a>
                            <a class="download-icon mb-3" target="_blank"
                                href="https://www.microsoft.com/si-lk/p/dp-education/9mv20p2nv5hp">
                                <img width="150px" src="./assets/icons/apps/ms.png" alt="">
                            </a>
                        </div>
                        <div class="link-group">
                            <p class="title" i18n="footer: download our app ">Bestweb Award</p>
                            <a class="download-icon mb-3">
                                <img width="135px" src="./assets/images/Gold-Best Education Website.png" alt="">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="bottom d-flex flex-column flex-md-row justify-content-between w-100">
                <div class="d-flex flex-column flex-md-row">
                    <div class="b-block">
                        <img height="60px" *ngIf="getCurrentLang()=='en'" src="./assets/images/dp foundation/en.png"
                            alt="Foundation Logo">
                        <img height="60px" *ngIf="getCurrentLang()=='si'" src="./assets/images/dp foundation/si.png"
                            alt="Foundation Logo">
                        <img height="60px" *ngIf="getCurrentLang()=='ta'" src="./assets/images/dp foundation/ta.png"
                            alt="Foundation Logo">
                    </div>
                </div>
                <div class="d-flex">
                    <div class="b-block terms-link">
                        <a href="" i18n="footer: terms ">Terms of use</a>
                    </div>
                    <div class="b-block terms-link">
                        <a href="" i18n="footer: cookie ">Cookie Notice</a>
                    </div>
                    <div class="b-block terms-link">
                        <a href="./assets/documents/DP Education Privacy Principles.pdf" target="_blank"
                            i18n="footer: policy ">Privacy Policy</a>
                    </div>
                </div>
                <!-- <div class="d-flex flex-row align-items-center">
                    <p class="title" i18n="footer| follow us">Follow us:</p>
                    <div class="s-block">
                        <a href="https://www.facebook.com/dpeducation.org">
                            <img src="./assets/icons/social/fb.svg" alt="">
                        </a>
                    </div>
                    <div class="s-block">
                        <a href="https://www.youtube.com/channel/UC0CXMeU0432EMgBnlBaY_yA">
                            <img src="./assets/icons/social/yt.svg" alt="">
                        </a>
                    </div>
                    <div class="s-block">
                        <a href="https://t.me/dpedugroup">
                            <img src="./assets/icons/social/tg.svg" alt="">
                        </a>
                    </div>
                    <div class="s-block">
                        <a href="https://www.instagram.com/dpeducation.lk">
                            <img src="./assets/icons/social/ig.svg" alt="">
                        </a>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</section>
<div class="copyright-row text-white d-flex flex-column align-items-center justify-content-center"
    style="height: 60px;">
    <div>

        © DP EDUCATION. ALL RIGHTS RESERVED | <a href="https://hayleysbpo.com/" title="HBSI" class="text-white"
        target="_blank">CONCEPT & DESIGN BY HBSI</a>.
    </div>
    <div>
        DEVELOPED BY <a href="https://www.databoxtech.io/" title="Databox Technologies" class="text-white"
            target="_blank">DATABOX</a>
    </div>
</div>