import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-logout-confirm',
  templateUrl: './logout-confirm.component.html',
  styleUrls: ['./logout-confirm.component.scss']
})
export class LogoutConfirmComponent implements OnInit{
  
  @Input() modalRef: any = null;
  
  constructor(public authSrv: AuthService, @Inject(LOCALE_ID) public locale: string){}
  
  ngOnInit(): void {}

  confirmLogout(){
    this.authSrv.logout();
    this.modalRef.close();
    window.location.reload();
  }

  cancel(){
    this.modalRef.close();
  }
}
