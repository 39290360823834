import { Component, Input } from '@angular/core';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {

  @Input() crumbs: Breadcrumb[] = [];
  hide= false;

  constructor(private navSrv: NavService){
    navSrv.breadcrumbs.subscribe(crumbs => {
      this.crumbs = crumbs;
      this.hide = !crumbs || crumbs.length == 0;
    })
  }

}



export interface Breadcrumb{
  lable: string;
  route: string;
}