<div
    class="container-fluid secondary-search-wrapper text-center w-100 d-flex align-items-center justify-content-center">
    <global-search customClass="light" class="global-search" [searchQuery]="query"></global-search>
</div>
<div class="container main-search-results-wrapper d-flex mt-4 mb-5" *ngIf="query != null">
    <div class="filters">
        <div class="title" i18n="searchPage: Filters">Filters</div>

        <div class="filer-block">
            <div class="label" i18n="searchPage: Grade">Grade</div>
            <div class="filter-item dropdown">
                <a class="filter-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <ng-container *ngIf="selectedGrade">
                        <span [ngStyle]="{'font-size': locale == 'ta' ? '12px' : ''}">
                            <span i18n="searchPage: GradeBefore">Grade</span> {{ selectedGrade }}
                        </span>
                    </ng-container>

                    <ng-container *ngIf="!selectedGrade">
                        <span [ngStyle]="{'font-size': locale == 'ta' ? '12px' : ''}"
                            i18n="searchPage: selectGrade">Select Grade</span>
                        <!-- {{ gradeToDisplay }} -->
                    </ng-container>
                </a>
                <ul class="dropdown-menu p-3">
                    <ng-container *ngFor="let grade of grades">
                        <li class="mb-2" (click)="gradeChange(grade);">
                            {{grade[locale]}}
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>

        <div class="filer-block" *ngIf="selectedGrade > 11">
            <div class="label" i18n="searchPage: Stream">Stream</div>
            <div class="filter-item dropdown">
                <a class="filter-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <ng-container *ngIf="selectedStream">
                        <span [ngStyle]="{'font-size': locale == 'ta' ? '12px' : ''}">
                            {{ stream[locale] }}
                        </span>
                    </ng-container>

                    <ng-container *ngIf="!selectedStream">
                        <span [ngStyle]="{'font-size': locale == 'ta' ? '12px' : ''}"
                            i18n="searchPage: selectStream">Select Stream</span>
                    </ng-container>
                </a>
                <ul class="dropdown-menu p-3">
                    <ng-container *ngFor="let stream of streams">
                        <li class="mb-2" (click)="streamChange(stream);">
                            {{stream[locale]}}
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>

        <div class="filer-block" *ngIf="subjects">
            <div class="label" i18n="searchPage: Subject">Subject</div>
            <div class="filter-item dropdown" [ngClass]="{'pe-none opacity-50': subjects.length < 1}">
                <a class="filter-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <!-- {{selectedSubject ? selectedSubject : 'Select Subject'}} -->
                    <ng-container *ngIf="selectedSubject">
                        <span [ngStyle]="{'font-size': locale == 'ta' ? '12px' : ''}">
                            {{ selectedSubject}}
                        </span>
                    </ng-container>

                    <ng-container *ngIf="!selectedSubject">
                        <span [ngStyle]="{'font-size': locale == 'ta' ? '12px' : ''}"
                            i18n="searchPage: selectSubject">Select Subject</span>
                    </ng-container>
                </a>
                <ul class="dropdown-menu p-3">
                    <ng-container *ngFor="let subject of subjects">
                        <li class="mb-2" (click)="subjectChange(subject);">
                            {{subject.name}}
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>
    <div class="results p-3">

        <button class="btn bg-transparent mobile-filter mb-4" (click)="openMobileFilter();">
            <img class="me-2" src="./assets/icons/button/filter.svg" alt="">
            <span i18n="searchPage: filtersMobile">
                Filters
            </span>
        </button>

        <div class="showing-results-for">
            <span *ngIf="locale == 'en'">Showing results for</span>
            &nbsp;
            <span class="string">"{{ query }}"</span>
            &nbsp;
            <span *ngIf="locale == 'si'">සඳහා ප්‍රතිඵල</span>
            <span *ngIf="locale == 'ta'">இதற்கான முடிவுகளைக் காட்டுகிறது</span>
        </div>

        <div class="results-wrap mb-5">
            <!-- <div class="title">Lessons</div> -->

            <div class="result-block" *ngFor="let lesson of lessonResults.items" (click)="goToResult(lesson)">
                <div class="type">
                    <div *ngIf="lesson.type == 'lesson'" i18n="searchPage: lessonLabel">
                        Lesson
                    </div>
                    <div *ngIf="lesson.type == 'chapter'" i18n="searchPage: chapterLabel">
                        Chapter
                    </div>
                </div>
                <div class="body d-flex gap-2">
                    <div class="icon">
                        <img [src]="lesson.type == 'lesson'? './assets/icons/search/lesson.svg' : './assets/icons/search/chapter.svg'"
                            alt="">
                    </div>
                    <div class="text">
                        <ng-container *ngIf="lesson.type == 'lesson'; chapter">{{lesson.name}}</ng-container>
                        <ng-container #chapter>{{lesson.sub_lesson_name}}</ng-container>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center w-100" *ngIf="lessonResults.totalPages > 1">
                <ngb-pagination (pageChange)="loadLessonPage($event)" [collectionSize]="lessonResults.totalItems"
                    [pageSize]="pageSize" [(page)]="lessonResults.currentPage" [maxSize]="3" [rotate]="false"
                    [ellipses]="true" [directionLinks]="true" [rotate]="true" [boundaryLinks]="false" />
            </div>
            <ng-template #noLessons>
                <div class="text-center text-muted fs-6">
                    No Lessons Found
                </div>
            </ng-template>
        </div>

        <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
    </div>
</div>


<div class="py-5 text-center" *ngIf="query == null;">
    <div class="text-muted">Discover a wealth of educational lessons! Use our search box to unlock new insights and
        knowledge</div>
</div>