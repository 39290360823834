<section class="mag-bit-sec container">
    <div class="container inner my-3 d-flex align-items-start justify-content-center gap-5">
        <div class="brand-box">
            <p i18n="magicbit brand: title">Program Robots Anytime, Anywhere</p>
            <div class="brand-logos d-flex align-items-start justify-content-start flex-column">
                <img class="brand-img" src="./assets/images/brands/{{locale}}/remote-robotics.png" alt="">
                <img class="brand-img" src="./assets/images/fancy/mb-logo.png" alt="">
            </div>
        </div>
        <div class="stat-box">
            <p i18n="magicbit stat: title">
                Explore real-time programming on our virtual robotics platform, DP Education Remote Robotics. Connect to our online robot farm, access 24/7, and practice <span class="spl-text">robotics, IoT</span> and <span class="spl-text">AI</span> with our curriculum.
            </p>
            <div class="stats d-flex flex-column flex-md-row align-items-md-start justify-content-md-start align-items-center justify-content-center gap-5">
                <div class="stat">
                    <div class="count">60+</div>
                    <div class="label" i18n="magicbit stat: lessons">Lessons</div>
                </div>
                <div class="stat">
                    <div class="count">24/7</div>
                    <div class="label" i18n="magicbit stat: access">Access</div>
                </div>
                <a class="btn-start btn btn-danger d-flex align-items-center justify-content-center" href="https://www.dpitcampus.lk/lms/course/index.php?categoryid=4" target="_blank" i18n="magicbit stat: get-started">
                    Get Started
                </a>
            </div>
        </div>
    </div>
</section>