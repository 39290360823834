import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from '../models/auth-user';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public apiBase = `${environment.apiBaseUrl}/auth`;
  public isLoggedIn: boolean = false;
  private currentUserSubject: BehaviorSubject<User | null>;
  public currentUser: Observable<User | null>;

  constructor(private http: HttpClient, @Inject(LOCALE_ID) public locale: string) {
    let jsn: any = localStorage.getItem('__dp-user');
    this.currentUserSubject = new BehaviorSubject<User | null>(JSON.parse(jsn));
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentUser.subscribe(user => {
      if(user && user.token){
        this.isLoggedIn = true;
      }else{
        this.isLoggedIn = false;
      }
    })
  }

  public get currentUserValue(): User | null {
    return this.currentUserSubject.value;
  }

  public getJwtToken(){
    return this.currentUserValue?.token ? this.currentUserValue?.token : null ;
  }

  public userLogin(data: any){
    return this.http.post<User>(`${this.apiBase}/user-login`,data);
  }

  verifyEmailToken(data: any){
    return this.http.post<any>(`${this.apiBase}/verify-email`,data);
  }

  public processAuthResponse(response: any){
    if(response && response.access_token){
      const user = (new User()).deserialize(response);
      // console.log(user);
      localStorage.setItem('__dp-user', JSON.stringify(user));
      this.currentUserSubject.next(user);
      return user;
    }
    return null;
  }

  logout() {
    localStorage.removeItem('__dp-user');
    this.currentUserSubject.next(null);
  }

  getUser(){
    let user = JSON.parse(localStorage.getItem('__dp-user') ?? '') as User;
    this.currentUserSubject.next(user);
    return user;
  }

  registerByEmail(data:any){
    return this.http.post<any>(`${this.apiBase}/register`, data);
  }

  forgotPassword(data:any){
    return this.http.post<any>(`${this.apiBase}/forgot`, data);
  }

  updatePw(data:any){
    return this.http.post<any>(`${this.apiBase}/updatepw`, data);
  }

  verifyResetToken(data:any){
    return this.http.post<any>(`${this.apiBase}/verify-reset`, data);
  }
}
