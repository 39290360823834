import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from '../core/models/subject.model';
import { MobileSearchFilterComponent } from '../core/popups/mobile-search-filter/mobile-search-filter.component';
import { DataService } from '../core/services/data.service';
import { GlobalSearchService, SearchParams } from '../core/services/global-search.service';
import { NavService } from '../core/services/nav.service';
import { SubjectsService } from '../core/services/subjects.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  public subjects: Subject[] = [];

  lessonResults: any = [];
  indexResults: any = [];
  public grades: any = [];
  public streams: any = [];
  streamid?: any;

  public gradeToDisplay: any = 'Select Grade'
  public streamToDisplay: any = 'Select Stream'
  public subjectToDisplay: any = 'Select Subject'

  public query: string = '';
  public selectedGrade: any;
  public stream:any = null;
  public selectedStream: string = '';
  public selectedSubject: string = '';
  public lessonActivePage: number = 1;
  public indexActivePage: number = 1;
  public pageSize: number = 10;


  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private route: ActivatedRoute,
    private searchService: GlobalSearchService,
    private data: DataService,
    private spinner: NgxSpinnerService,
    private subjectSrv: SubjectsService,
    private router: Router,
    private modalService: NgbModal,
    private navSrv: NavService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.query = params['query'] || null;
      this.selectedGrade = params['grade'] || '';
      this.selectedSubject = params['subject'] || '';
      this.selectedStream = params['stream'] || '';
      this.lessonActivePage = params['lesson_page'] || 1;

      this.navSrv.clearBreadcrumbs();

      if (this.query == null) {
        let str_array: any = { "si": "සෙවීම", "en": "Search", "ta": "தேடல்" }
        let tmp = str_array[this.locale]
        this.navSrv.setCrumb(tmp, "search", true)
        
      }else{
        // Set crumb with search query if query is available in the url
        let str_array: any = { "si": "සෙවීම", "en": "Search", "ta": "தேடல்" }
        let tmp = str_array[this.locale]
        this.navSrv.setCrumb(tmp, "search", true)
        
        this.navSrv.setCrumb(this.query, `search?query=${this.query}`)
        
      }

      if (this.selectedStream == '') {
        this.stream = null;
        console.log('stream is empty');
      }else{
        this.stream = this.data.getStreamByKey(this.selectedStream)
        console.log('thissss issss stream');
        console.log(this.stream);
        
        
      }
      this.getSubjects();
      this.getLessons();
    });

    // Get Grades
    this.grades = this.data.grades;
    this.streams = this.data.streams;
    // console.log('this.stream');
    // console.log(this.stream);

  }

  // Called when the user performs a search
  searchChanged() {
    // Update search parameters in the service
    this.searchService.setSearchParams({
      query: this.query,
      grade: this.selectedGrade.toString(),
      stream: this.stream ? this.stream.key : '',
      subject: this.selectedSubject,
      lesson_page: this.lessonActivePage
    });

    console.log('search');

  }

  gradeChange(grade: any) {
    // console.log(grade);

    this.selectedGrade = grade['id'];
    this.selectedSubject = '';
    this.selectedStream = '';
    this.streamToDisplay = 'Select Stream'
    this.subjectToDisplay = 'Select Subject'
    this.lessonActivePage = 1
    this.getSubjects();
    this.searchChanged();

  }

  subjectChange(subject: any) {
    console.log(subject['name']);
    this.selectedSubject = subject['name'];
    this.searchChanged();

  }

  streamChange(stream: any) {
    this.stream = stream;

    console.log('this.stream');
    console.log(this.stream.key);

    this.selectedSubject = '';

    this.selectedStream = this.stream.key;
    this.streamToDisplay = this.stream[this.locale];
    this.streamid = this.stream.id;

    this.getSubjects();
    this.searchChanged();
  }

  getSubjects() {

    if (this.stream != null) {
      // this.streamid = this.data.getStreamid(this.selectedStream)
      this.subjectSrv.getStreamSubjects(this.stream.id).subscribe(res => {
        this.subjects = res.subjects as Subject[];
      })
    } else {
      this.subjectSrv.getSubjects(this.selectedGrade.toString()).subscribe(res => {
        this.subjects = res;
      })
    }

    // console.log(this.subjects);

  }

  loadLessonPage(page: number) {
    this.lessonActivePage = page;
    this.searchChanged();
  }

  loadIndexPage(page: number) {
    this.indexActivePage = page;
    this.searchChanged();
  }

  getLessons() {
    const searchParams: SearchParams = {
      query: this.query,
      grade: this.selectedGrade ? this.selectedGrade.toString() : '',
      stream: this.stream ? this.stream.en : '',
      subject: this.selectedSubject,
      lesson_page: this.lessonActivePage
    };
    this.spinner.show();
    this.searchService.getLessons(searchParams).subscribe((response) => {
      this.lessonResults = response;
      this.spinner.hide();
      // console.log(this.lessonResults);
    });
  }

  goToResult(result: any) {
    // console.log(result);


    var course = result.courses_id;

    // console.log(course);


    if (result.type == 'lesson') {
      this.router.navigate(['/results/for', course], {
        queryParams: {
          query:this.query
        }
      });
    } else {
      this.router.navigate(['/results/for', course], {
        queryParams: {
          query:this.query,
          lessonId: result.lesson_id,
          startTime: result.start,
        }
      });
    }
  }

  goToLesson(lesson: any) {
    // console.log(lesson);

    let course = lesson.courseId
    let grade = lesson.subjects_grade
    let subject = lesson.subjects_name

    if (grade < 12) {
      this.router.navigate(['/grade', grade, subject, course]);
    } else {
      this.router.navigate(['/grade', grade, 'stream', this.selectedStream, subject, course]);
    }
  }

  goToIndex(index: any) {
    // console.log(index);

    let course = index.courses_id
    let grade = index.subjects_grade
    let subject = index.subjects_name

    let startTime = index.start;
    let lessonId = index.lesson_id;

    if (grade < 12) {
      this.router.navigate(['/grade', grade, subject, course], {
        queryParams: {
          lessonId: lessonId,
          startTime: startTime
        }
      });
    } else {
      this.router.navigate(['/grade', grade, 'stream', this.selectedStream, subject, course], {
        queryParams: {
          lessonId: lessonId,
          startTime: startTime
        }
      });
    }
  }

  openMobileFilter() {
    const modalRef = this.modalService.open(MobileSearchFilterComponent, { centered: true, backdrop: true });
    // modalRef.componentInstance.data = 'Reset Password';

    modalRef.result.then((result) => {
      // Handle modal close event if needed
    }, (reason) => {
      // Handle modal dismiss event if needed
    });
  }
}