<div class="sec-nav d-flex align-items-center justify-content-end justify-content-lg-around">
    
    <div i18n="secNav: leadership" class="d-none d-lg-flex align-items-center nav-item" [routerLink]="['/leadership']"
        routerLinkActive="active">
        Leadership
    </div>
    <div i18n="secNav: board of trustees" class="d-none d-lg-flex align-items-center nav-item" [routerLink]="['/board-of-directors']"
        routerLinkActive="active">
        Board of Trustees
    </div>
    <div i18n="secNav: our-team" class="d-none d-lg-flex align-items-center nav-item" [routerLink]="['/our-team']" routerLinkActive="active">
        Our Team
    </div>
    <div i18n="secNav: content specialists" class="d-none d-lg-flex align-items-center nav-item" [routerLink]="['/content-specialists']"
        routerLinkActive="active">
        Content Specialists
    </div>
    <div i18n="secNav: our partners" class="d-none d-lg-flex align-items-center nav-item" [routerLink]="['/our-partners']"
        routerLinkActive="active">
        Our Partners
    </div>
    <div i18n="secNav: careers" class="d-none d-lg-flex align-items-center nav-item" [routerLink]="['/careers']" routerLinkActive="active">
        Careers
    </div>
    <div i18n="secNav: gallery" class="d-none d-lg-flex align-items-center nav-item" [routerLink]="['/gallery']" routerLinkActive="active">
        Gallery
    </div>
    <div i18n="secNav: help" class="d-none d-lg-flex align-items-center nav-item" [routerLink]="['/help-center']" routerLinkActive="active">
        Help
    </div>
    <button type="button" class="btn btn-prime d-flex d-block d-lg-none text-white" data-bs-toggle="offcanvas"
        data-bs-target="#mobSecMenu" aria-controls="mobSecMenu">
        <i class="bi bi-list"></i>
    </button>
</div>

<div class="offcanvas offcanvas-start" tabindex="-1" id="mobSecMenu" aria-labelledby="mobSecMenuContainer">
    <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="mobSecMenuContainer" i18n="dp education: dp education">DP Education</h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
        <div class="d-flex flex-column">
            <div i18n="secOffCanvas: leadership" data-bs-dismiss="offcanvas" [routerLink]="['/leadership']" class="nav-item">Leadership</div>
            <div i18n="secOffCanvas: board of trustees" [routerLink]="['/board-of-directors']" class="nav-item"
                data-bs-dismiss="offcanvas" routerLinkActive="active">Board of Trustees</div>
            <div i18n="secOffCanvas: our teams" data-bs-dismiss="offcanvas" [routerLink]="['/our-team']" routerLinkActive="active" class="nav-item">Our
                Team</div>
            <div i18n="secOffCanvas: our specialists" data-bs-dismiss="offcanvas" [routerLink]="['/content-specialists']" routerLinkActive="active" class="nav-item">Content
                Specialists</div>
            <div i18n="secOffCanvas: our partners" data-bs-dismiss="offcanvas" [routerLink]="['/our-partners']" routerLinkActive="active" class="nav-item">Our
                Partners</div>
            <div i18n="secOffCanvas: careers" data-bs-dismiss="offcanvas" [routerLink]="['/careers']" routerLinkActive="active" class="nav-item">Careers</div>
            <div i18n="secOffCanvas: gallery" data-bs-dismiss="offcanvas" [routerLink]="['/gallery']" routerLinkActive="active" class="nav-item">Gallery</div>
            <div i18n="secOffCanvas: help center" data-bs-dismiss="offcanvas" [routerLink]="['/help-center']" routerLinkActive="active" class="nav-item">Help
                Center</div>
        </div>
    </div>
</div>