import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MetaService {

  apiUrl = environment.apiBaseUrl;
  loaded = false;

  constructor(private meta: Meta, private title: Title, private http: HttpClient, @Inject(LOCALE_ID) public locale: string) { 
  }


  upadtePathMeta(path: string){
    this.http.post<any>(`${this.apiUrl}/seo/meta/${this.locale}`, {path: path})
    .subscribe(data => {
        this.setMetaTags(data)
    })
  }

  setMetaTags(metaDetails: any){
    this.title.setTitle(metaDetails.title);

    this.meta.updateTag({ name: 'description', content: metaDetails.description });
    this.meta.updateTag({ name: 'keywords', content: metaDetails.keywords });

    this.meta.updateTag(
      { property: 'og:title', content: metaDetails.title },
    );
    this.meta.updateTag(
      { property: 'og:description', content: metaDetails.description },
    );
  }

  setMetaTag(name: string, content: string): void {
    this.meta.updateTag({ name, content });
  }

  setMetaProperty(property: string, content: string): void {
    this.meta.updateTag({ property, content });
  }

  removeMetaTag(tag: string): void {
    this.meta.removeTag(tag);
  }
}
