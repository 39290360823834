import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface SearchParams {
  query: string;
  grade?: string;
  stream?: string;
  subject?: string;
  lesson_page?: number;
  size?: number;
  lang?: string;
}

@Injectable({
  providedIn: 'root'
})

export class GlobalSearchService {
  public apiBaseLesson = `${environment.apiBaseUrl}/lessons/search/global`;
  public apiBaseLessonIndex = `${environment.apiBaseUrl}/lessons/search/global-index`;

  private _pageSize: number = 10;

  constructor(private http: HttpClient, private router: Router, @Inject(LOCALE_ID) public locale: string,) { }

  setSearchParams(params: SearchParams): void {
    this.router.navigate(['/search'], { queryParams: params });
  }

  getLessons(params: SearchParams): Observable<any> {
    // console.log('params.stream');
    // console.log(params.stream);

    const { grade = '', subject = '', query = '', stream, lesson_page = '1', size = this._pageSize, lang = this.locale } = params;
    // const url = `${this.apiBaseLesson}?grade=${grade}&subject=${subject}&search=${query}&stream='${stream}'&lesson_page=${lesson_page}&size=${size}&lang=${lang}`;

    // Construct the URL based on the provided parameters
    let url = `${this.apiBaseLesson}?grade=${grade}&subject=${subject}&search=${query}&lesson_page=${lesson_page}&size=${size}&lang=${lang}`;

    // If stream is not null or undefined, add it to the URL
    if (stream !== null && stream !== undefined && stream !== '') {
      url += `&stream=${stream}`;
    }

    return this.http.get(url).pipe(
      catchError((error: any) => {
        console.error('Error occurred while fetching Lessons:', error);
        throw error;
      })
    );
  }
}
