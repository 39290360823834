<div class="modal-body p-5">
    <div class="title" i18n="searchPop: Filters">Filters</div>
    <div class="filer-block">
        <div class="label" i18n="searchPop: Stream">Grade</div>
        <div class="filter-item dropdown">
            <a class="filter-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                <ng-container *ngIf="selectedGrade">
                    <span [ngStyle]="{'font-size': locale == 'ta' ? '12px' : ''}">
                        <span i18n="searchPop: GradeBefore">Grade</span> {{ selectedGrade }}
                    </span>
                </ng-container>

                <ng-container *ngIf="!selectedGrade">
                    <span [ngStyle]="{'font-size': locale == 'ta' ? '12px' : ''}"
                        i18n="searchPop: selectGrade">Select Grade</span>
                    <!-- {{ gradeToDisplay }} -->
                </ng-container>
            </a>
            <ul class="dropdown-menu p-3">
                <ng-container *ngFor="let grade of grades">
                    <li class="mb-2" (click)="gradeChange(grade);">
                        {{grade[locale]}}
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>

    <div class="filer-block" *ngIf="selectedGrade > 11">
        <div class="label" i18n="searchPop: Stream">Stream</div>
        <div class="filter-item dropdown">
            <a class="filter-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                <ng-container *ngIf="selectedStream">
                    <span [ngStyle]="{'font-size': locale == 'ta' ? '12px' : ''}">
                        {{ stream[locale] }}
                    </span>
                </ng-container>
                
                <ng-container *ngIf="!selectedStream">
                    <span [ngStyle]="{'font-size': locale == 'ta' ? '12px' : ''}" i18n="searchPop: selectStream">Select Stream</span>
                </ng-container>
            </a>
            <ul class="dropdown-menu p-3">
                <ng-container *ngFor="let stream of streams">
                    <li class="mb-2" (click)="streamChange(stream);">
                        {{stream[locale]}}
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>

    <div class="filer-block" *ngIf="subjects">
        <div class="label" i18n="searchPop: Subject">Subject</div>
        <div class="filter-item dropdown" [ngClass]="{'pe-none opacity-50': subjects.length < 1}">
            <a class="filter-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                <!-- {{selectedSubject ? selectedSubject : 'Select Subject'}} -->
                <ng-container *ngIf="selectedSubject">
                    <span [ngStyle]="{'font-size': locale == 'ta' ? '12px' : ''}">
                        {{ selectedSubject}}
                    </span>
                </ng-container>
                
                <ng-container *ngIf="!selectedSubject">
                    <span [ngStyle]="{'font-size': locale == 'ta' ? '12px' : ''}" i18n="searchPop: selectSubject">Select Subject</span>
                </ng-container>
            </a>
            <ul class="dropdown-menu p-3">
                <ng-container *ngFor="let subject of subjects">
                    <li class="mb-2" (click)="subjectChange(subject);">
                        {{subject.name}}
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>

    <div class="d-flex justify-content-center flex-column align-items-center gap-2 mt-5">
        <button class="btn btn-apply" (click)="apply();" i18n="searchPop: Apply">Apply</button>
        <button class="btn btn-cancel" (click)="cancel();" i18n="searchPop: Close">Close</button>
    </div>
</div>
