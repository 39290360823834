<div class="modal-body text-center justify-content-center position-relative">
    <button class="btn rounded-pill pt-4 pe-4 position-absolute top-0 end-0" (click)="activeModal.close('Close click')"><i class="bi bi-x-circle fs-3 d-flex"></i></button>
    <img width="100%" src="./assets/images/fancy/alGreet.png" alt="">
    <div class="title" *ngIf="locale == 'en'">Score High In Your A/L Exam With DP Education</div>
    <div class="title" *ngIf="locale == 'si'">ඩීපී එඩියුකේෂන් සමඟ උසස් පෙළ විභාගයෙන් ඉහළ ලකුණු ලබා ගන්න </div>
    <div class="title" *ngIf="locale == 'ta'">டிபி எடியூகேஷனுடன்  உங்கள் A/L தேர்வில் அதிக மதிப்பெண் பெறுங்கள்</div>
    <a href="./grade/13/stream" class="btn mx-auto mb-2 btn-al rounded-pill d-flex align-items-center justify-content-center" (click)="activeModal.close('Close click')">
        <span *ngIf="locale == 'en'">Get Started</span>
        <span *ngIf="locale == 'si'">ආරම්භ කරන්න</span>
        <span *ngIf="locale == 'ta'">தொடங்குங்கள்</span>
    </a>
</div>