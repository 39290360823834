import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from '../../models/subject.model';
import { DataService } from '../../services/data.service';
import { GlobalSearchService } from '../../services/global-search.service';
import { SubjectsService } from '../../services/subjects.service';

@Component({
  selector: 'app-mobile-search-filter',
  templateUrl: './mobile-search-filter.component.html',
  styleUrls: ['./mobile-search-filter.component.scss']
})
export class MobileSearchFilterComponent implements OnInit {

  @Input() data: any;
  public query: string = '';
  public grades: any = [];
  public streams: any = [];
  public subjects: Subject[] = [];
  streamid?: any;

  public selectedStream: string = '';
  public stream:any = null;
  public selectedGrade: any;
  public selectedSubject: string = '';

  public streamToDisplay: any = 'Select Stream'
  public subjectToDisplay: any = 'Select Subject'

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    public activeModal: NgbActiveModal,
    private router: ActivatedRoute,
    private dataService: DataService,
    private subjectSrv: SubjectsService,
    private searchService: GlobalSearchService
  ) {
    this.grades = this.dataService.grades;
    this.streams = this.dataService.streams;
  }

  ngOnInit(): void {
    this.router.queryParams.subscribe(params => {
      this.query = params['query'] || null;
      this.selectedGrade = params['grade'] || '';
      this.selectedSubject = params['subject'] || '';
      this.selectedStream = params['stream'] || '';

      if (this.selectedStream == '') {
        this.stream = null;
      }else{
        this.stream = this.dataService.getStreamByKey(this.selectedStream)
      }
      this.getSubjects();
    });
  }

  getSubjects() {

    if (this.stream != null) {
      // this.streamid = this.data.getStreamid(this.selectedStream)
      this.subjectSrv.getStreamSubjects(this.stream.id).subscribe(res => {
        this.subjects = res.subjects as Subject[];
      })
    } else {
      this.subjectSrv.getSubjects(this.selectedGrade.toString()).subscribe(res => {
        this.subjects = res;
      })
    }

    // console.log(this.subjects);

  }

  gradeChange(grade: any) {
    // console.log(grade);

    this.selectedGrade = grade['id'];
    this.selectedSubject = '';
    this.selectedStream = '';
    this.streamToDisplay = 'Select Stream'
    this.subjectToDisplay = 'Select Subject'
    this.getSubjects();
    // this.searchChanged();

  }

  streamChange(stream: any) {
    this.stream = stream;

    // console.log('this.stream');
    // console.log(this.stream.key);

    this.selectedSubject = '';

    this.selectedStream = this.stream.key;
    this.streamToDisplay = this.stream[this.locale];
    this.streamid = this.stream.id;

    this.getSubjects();
  }


  subjectChange(subject: any) {
    console.log(subject['name']);
    this.selectedSubject = subject['name'];
  }

  cancel() {
    this.activeModal.close();
  }

  // Called when the user performs a search
  apply() {
    // Update search parameters in the service
    this.searchService.setSearchParams({
      query: this.query,
      grade: this.selectedGrade.toString(),
      stream: this.selectedStream,
      subject: this.selectedSubject
    });

    // console.log('search');
    this.activeModal.close(this.data);

  }

}
