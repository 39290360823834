import { Deserializable } from "./deserializable.model";

export class User implements Deserializable{
    id: number | undefined ;
    name: string | undefined;
    email: string | undefined;
    role: string | undefined;
    token: string | undefined;
    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}