import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';
import { MetaService } from './core/services/meta.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'v4';

  constructor(private metaSrv: MetaService, private router: Router){
    router.events.pipe(filter((event => event instanceof NavigationEnd)))
    .subscribe((event: any) => {
      this.processPageChange(event.url);
    });

  }

  ngOnInit(): void {
  }

  processPageChange(url: string){
    // console.log('processPageChange', url)
    this.metaSrv.upadtePathMeta(url);
  }


}
