import { Component, Inject, LOCALE_ID } from '@angular/core';

@Component({
  selector: 'app-magic-bit-section',
  templateUrl: './magic-bit-section.component.html',
  styleUrls: ['./magic-bit-section.component.scss']
})
export class MagicBitSectionComponent {

  constructor(@Inject(LOCALE_ID) public locale: string){}
}
