import { Component, Inject, LOCALE_ID } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-al-greet-modal-content',
  templateUrl: './al-greet-modal-content.component.html',
  styleUrls: ['./al-greet-modal-content.component.scss']
})
export class AlGreetModalContentComponent {

  constructor(public activeModal: NgbActiveModal, @Inject(LOCALE_ID) public locale: string){}
}
