import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from '../models/subject.model';
import { LOCALE_ID, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Stream } from '../models/stream.model';

@Injectable({
  providedIn: 'root'
})
export class SubjectsService {

  public apiBase = `${environment.apiBaseUrl}`;

  constructor(@Inject(LOCALE_ID) public locale: string,private http: HttpClient) { }

  getSubjects(grade: string): Observable<Subject[]> {
    return this.http.get<Subject[]>(`${this.apiBase}/subjects/${grade}/${this.locale}`);
  }

  getStreamSubjects(stream: string): Observable<Stream> {
    return this.http.get<Stream>(`${this.apiBase}/streams/${stream}/${this.locale}`);
  }

  getSubject(id: number): Observable<Subject> {
    return this.http.get<Subject>(`${this.apiBase}/subjects/${id}`);
  }

  getSubjectBykey(grade: string, key: string): Observable<Subject> {
    return this.http.get<Subject>(`${this.apiBase}/subjects/${grade}/${this.locale}/${key}`);
  }
}
