import { Component, ElementRef, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit {

  @Input() customClass: string = 'light';
  @Input() searchQuery: string = '';
  searchPlaceholder:any ={"si":"සෙවීම","en":"Search","ta":"தேடல்"} 
  
  constructor(@Inject(LOCALE_ID) public locale: string, private elementRef: ElementRef, private router: Router) {}

  ngOnInit(): void {

  }

  navigateToSearch() {
    if (this.searchQuery) {
      this.router.navigate(['/search'], { queryParams: { query: this.searchQuery } });
    }
  }

  focusInput() {
    const inputElement: HTMLInputElement = this.elementRef.nativeElement.querySelector('.g-s-input');
    if (inputElement) {
      inputElement.focus();
      inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length);
    }
  }
}
