import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';


function getWindow (): any {
  return window;
}

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  get nativeWindow() {
    return getWindow();
  }

  get isBrowser(): boolean{
    return isPlatformBrowser(this.platformId);
  }
}
